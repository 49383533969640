import * as React from 'react'
import Layout from '../components/layout'
import { title } from '../components/my-module.css'
import { txtlo, hpmain } from '../components/layout.module.css'
import { Link } from 'gatsby'

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <div className={hpmain}>
        <p>Hello!</p>
        <p>Το όνομά μου είναι Ιωάννα (Joan) και είμαι λάτρης της χειροτεχνίας όλων των ειδών. Φτιάχνω προσεγμένα χειροποίητα, μοναδικά αντικείμενα από ρητίνη, πορσελάνη και πηλό, με πολλή αγάπη και μεράκι!</p>
        <p>Βρείτε πρωτότυπες <Link to="/favors" className={txtlo}>μπομπονιέρες</Link> γάμου ή βάπτισης, για να εντυπωσιάσετε τους καλεσμένους σας σε μια τόσο ξεχωριστή ημέρα.</p>
        <p>Βρείτε τα ιδανικά <Link to="/products" className={txtlo}>προϊόντα</Link> για εσάς ή επιλέξτε να κάνετε ένα όμορφο δωράκι.</p>
        <br></br>
        <p>Για οποιαδήποτε περαιτέρω πληροφορία ή διευκρίνιση, παρακαλώ μη διστάσετε να <Link to="/contact" className={txtlo}>επικοινωνήσετε</Link> μαζί μας. 😍</p>
      </div>
    </Layout>
  )
}

export default AboutPage